import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ScrollView, Text, View, Animated } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';
import Select from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { FaCheck } from 'react-icons/fa';
import ArrowKeysReact from 'arrow-keys-react';
import TeacherView from './TeacherView';
//development
var app_builder_server = "https://appbuilderdev.herokuapp.com"
const server = "https://streamedbook.herokuapp.com"
//production
//var app_builder_server = "https://calm-chamber-06785.herokuapp.com/"
// const server = "http://ec2-3-134-253-9.us-east-2.compute.amazonaws.com:3000"
function convertToGMT(hours){
	var d = new Date();
	var n = d.getTimezoneOffset();
	return (hours + (n/60))%24
}

class AccountPage extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			page:"account",
			nonprofits:[],
			user:{},
			teachers:[],
			schools:[],
			students:[],
			startDate:"",
			endDate:"",
			startTime:"",
			endTime:"",
		}
	}


		componentDidUpdate(){
			// window.$("#startDate").datepicker({
			// 	dateFormat: "dd-mm-yy"
			// 	,	duration: "fast"
			// });

			// window.$("#endDate").datepicker({
			// 	dateFormat: "dd-mm-yy"
			// 	,	duration: "fast"
			// });
     window.$("#nonprofitheadline").fitText(4.3)
      // window.$(".nonprofit-table-text").fitText(1.0)
      window.$(".nonprofit-table-text-image").fitText(0.3)
      window.$(".logout-text").fitText(3)
	window.$("#account_page").css("background-color", this.state.page === "leaderboard" ? "#577B69":"white")

	      window.$("#edit_account_div").css("width",window.$("#edit_account_div").parent().width())


window.$("#edit_account_div").css("height",window.$("#edit_account_div").parent().height())

		}
		
	

	componentDidMount(){

		// alert("loaded account page")
		window.AccountPageComponent = this;

		window.$("#startDate").datepicker({
				dateFormat: "dd-mm-yy"
				,	duration: "fast"
			});

			window.$("#endDate").datepicker({
				dateFormat: "dd-mm-yy"
				,	duration: "fast"
			});
		


	

		
			  

	}



	render(){
		var that = this;
		if(that.state.page === "error"){
			return (
			<div>
				<p>It looks like there was an issue loading the account page. Come back later. :(</p>

			</div>

			)
		}

		if(that.state.page === "leaderboard"){
			
			var content = (
	
		<div style = {{backgroundColor:'#577B69'}}>

			<div >
				<p class = "text1">Vineyard App Camp</p>
				<p class = "text2">Win Awards and Prizes By Earning XP!</p>
			</div>

			
			<div class = "mainTable">

				<table class = "leaderTable">
					<thead>
						<tr>
							<th style = {{fontSize:"2vw"}}>Rank</th>
							<th style = {{fontSize:"2vw"}}>Name</th>
							<th style = {{fontSize:"2vw"}}>XP</th>
							<th style = {{fontSize:"2vw"}}>School</th>
							<th style = {{fontSize:"2vw"}}>Award</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td  style = {{fontSize:"1.2vw"}}>1</td>
							<td style = {{fontSize:"1.2vw"}}>Kyle</td>
							<td style = {{fontSize:"1.2vw"}}>100,000</td>
							<td style = {{fontSize:"1.2vw"}}>Vineyard University</td>
							<td style = {{fontSize:"1.2vw"}}><img class = "trophyImage" src = "https://cdn.dribbble.com/users/1013040/screenshots/4398952/movie.gif"/></td>
						</tr>
						<tr>
							<td>2</td>
							<td>Danny</td>
							<td>90,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
						<tr>
							<td>3</td>
							<td>PJ</td>
							<td>80,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
						<tr>
							<td>4</td>
							<td>David</td>
							<td>70,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
						<tr>
							<td>5</td>
							<td>Jack</td>
							<td>60,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
						<tr>
							<td>6</td>
							<td>Logan</td>
							<td>50,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
						<tr>
							<td>7</td>
							<td>Osswald</td>
							<td>40,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
						<tr>
							<td>8</td>
							<td>Brad</td>
							<td>30,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
						<tr>
							<td>9</td>
							<td>Manny</td>
							<td>20,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
						<tr>
							<td>10</td>
							<td>Louis</td>
							<td>10,000</td>
							<td>Vineyard University</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	


				)
		} else if(that.state.page === "teacher"){
			var content = (
				<div style = {{width:"100%",height:"100%"}}>
				 <TeacherView students = {this.state.students} schools = {this.state.schools} ></TeacherView>
				</div>
				)
		} else if(that.state.page === "account"){
			var content = (

<div id = "edit_account_div" class="bg-white shadow overflow-hidden sm:rounded-lg">

  <div style = {{height:"50%"}} class="border-t border-gray-200">
    <dl>
       <div >
        <dt style = {{marginTop:"5%",marginBottom:"2%"}} class="logout-text text-sm font-medium text-gray-500">
           Logout Button
        </dt>
        <dd class="logout-text mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          

					<button onClick ={function(){
						window.Cookies.remove('vineyardappcamp');
						window.Cookies.remove('vaclogin');
						window.app.setState({ page: "login" })
						window.account_page.dialog('close');
						window.location = "https://www.vacplatform.com"

					}} >Logout</button>
        </dd>
      </div>
    </dl>
  </div>
</div>
)
		} else if(that.state.page === "time_select"){
			var content = (
				<div style = {{marginTop:"5%",alignItems:'center'}} class="flex flex-col">
					<h1>Choose your class time</h1>
					<br></br>
					<label style = {{fontWeight:"bold"}} htmlFor="startDate">What day does your class start?</label>
					<input placeholder = "First day of class" type="text" name="startDate" id="startDate"></input>
					<label style = {{fontWeight:"bold"}} htmlFor="endDate">What day does your class end?</label>
					<input placeholder = "Last day of class"  type="text" name="endDate" id="endDate"></input>
					<label style = {{fontWeight:"bold"}} htmlFor="startTime">What time does your class start?</label>
					<div class="select-wrapper">
						<select value = {that.state.startTime} onChange = {function(a,b){
							that.setState({startTime:a.target.value})
						}}  name = "startTime" id="startTime" >
							<option value = "6"> 6 AM </option>
							<option value = "7"> 7 AM </option>
							<option value = "8"> 8 AM </option>
							<option value = "9"> 9 AM </option>
							<option value = "10"> 10 AM </option>
							<option value = "11"> 11 AM </option>
							<option value = "12"> 12 PM </option>
							<option value = "13"> 1 PM </option>
							<option value = "14"> 2 PM </option>
							<option value = "15"> 3 PM </option>
							<option value = "16"> 4 PM </option>
							<option value = "17"> 5 PM </option>
							<option value = "18"> 6 PM </option>
							<option value = "19"> 7 PM </option>
							<option value = "20"> 8 PM </option>
							<option value = "21"> 9 PM </option>
							<option value = "22"> 10 PM </option>
						</select>
					</div>
					<br></br>
					<label style = {{fontWeight:"bold"}} htmlFor="endTime">What time does your class end?</label>
					<div class="select-wrapper">
						<select value = {that.state.endTime} onChange = {function(a,b){
							that.setState({endTime:a.target.value})
						}}  name = "endTime" id="endTime" placeholder="00:00" >
							<option value = "6"> 6 AM </option>
							<option value = "7"> 7 AM </option>
							<option value = "8"> 8 AM </option>
							<option value = "9"> 9 AM </option>
							<option value = "10"> 10 AM </option>
							<option value = "11"> 11 AM </option>
							<option value = "10"> 12 PM </option>
							<option value = "13"> 1 PM </option>
							<option value = "14"> 2 PM </option>
							<option value = "15"> 3 PM </option>
							<option value = "16"> 4 PM </option>
							<option value = "17"> 5 PM </option>
							<option value = "18"> 6 PM </option>
							<option value = "19"> 7 PM </option>
							<option value = "20"> 8 PM </option>
							<option value = "21"> 9 PM </option>
							<option value = "22"> 10 PM </option>
						</select>
					</div>
					<button onClick = {function(){
						var start_date = new Date(window.$("#startDate").val())
						start_date.setHours(that.state.startTime)
						var end_date = new Date(window.$("#endDate").val())
						end_date.setHours(that.state.endTime)
						console.log(start_date);
						console.log(end_date);
					}} style = {{borderRadius:0, marginTop:"2%", backgroundColor:"#031D56"}} class = "button-1">Choose nonprofits</button>
				</div>
				)
		} else if(that.state.page === "nonprofits"){
			var content = (
				<div class="flex flex-col">
				{window.is_teacher ? (<h3 id = "nonprofitheadline" style = {{textAlign:"center", backgroundColor:"black", textDecorationLine:"underline", color:"red", alignText:"center"}} onClick = {function(){
					
				}}>Hello Teacher! Make sure only one student (or pair of students) is working on each page at a time! </h3>):null}
				
				  <div style = {{width:"100%"}}>
				    <div class="py-2 align-middle inline-block min-w-full">
				      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
				        <table id = "nonprofit_table" style = {{color:"black", width:window.$("#account_page").width()}} class="table-fixed divide-gray-200">
				          <thead class="bg-gray-50">
				            <tr>
				             
				              <th  scope="col" style = {{textAlign:"center"}} class="nonprofit-table-text w-1/12 text-xs font-medium text-gray-500 uppercase tracking-wider">
				                Name
				              </th>
				              <th  scope="col" style = {{textAlign:"center"}} class="nonprofit-table-text w-1/12  text-xs font-medium text-gray-500 uppercase tracking-wider">
				                Learn
				              </th>
				              <th  scope="col" style = {{textAlign:"center"}} class="nonprofit-table-text w-1/12  text-xs font-medium text-gray-500 uppercase tracking-wider">
				                Mission
				              </th>
				              <th scope="col" style = {{textAlign:"center"}}  class="nonprofit-table-text w-1/12  text-xs font-medium text-gray-500 uppercase tracking-wider">
				                App
				              </th>
				              <th  scope="col" style = {{textAlign:"center"}} class="nonprofit-table-text w-1/12  text-xs font-medium text-gray-500 uppercase tracking-wider">
				               Wireframe
				              </th>
				              <th style = {{textAlign:"center"}} scope="col" class="nonprofit-table-text w-1/12  text-xs font-medium text-gray-500 uppercase tracking-wider">
				                Finish App (🥇 300 XP!)
				              </th>
				             
				            </tr>
				          </thead>
				          <tbody class="bg-white divide-y divide-gray-200">
				          {that.state.nonprofits.sort(window.dynamicSort("np_name")).map(function(project){
				          	return (
				          		<tr style = {{height:window.$("account_page").height() * 0.3 }}>
				          		
					              <td
					              onClick = {function(){
					              	console.log(project)
					              	try{
						              	if(project.np_website.indexOf("http") === -1){
						              		window.open("https://" + project.np_website)
						              	} else{
						              		window.open(project.np_website)
						              	}
						              } catch(e){
						              	alert('Having trouble opening site. Try googling the nonprofit name');
						              }
					              	
					              }}

					              style = {{color:"black",textAlign:"center", fontWeight:"bold",fontSize:"1.3vw"}}
					               class="nonprofit-table-text w-2/12  text-xs font-medium text-gray-500 tracking-wider">
					              	{project.np_name}
					              </td>
					              <td style = {{textAlign:"center"}}  class="nonprofit-table-text w-2/12  text-xs font-medium text-gray-500 tracking-wider">
					              	{project.explain_video_link ? (<iframe height = "160px" width = "350px" src = {project.explain_video_link}></iframe>):
						              	('speechSynthesis' in window) ? (
						              	<div style = {{flexDirection:"row"}}>
						              	<button onClick = {function(){
						              		window.textToSpeech(project.np_mission)
						              	}} class = "nonprofit-table-text" style = {{whiteSpace:"normal",fontSize:"1.3vw"}} >🔊</button>
						              	<p onClick = {function(){
						              	 	alert(project.np_mission)
						              	 }} class = "nonprofit-table-text" style = {{whiteSpace:"normal",fontSize:"1.3vw"}}>

						              	See Mission
						              	</p>
						              	</div>):
						              	 (<p onClick = {function(){
						              	 	alert(project.np_mission)
						              	 }} class = "nonprofit-table-text" style = {{whiteSpace:"normal",color:"black",fontSize:"1.3vw"}}>

						              	See mission
						              	</p>)
						              }
					              </td>
					             <td style = {{textAlign:"center"}} 
					              class="nonprofit-table-text w-2/12 text-xs font-medium text-gray-500 tracking-wider">
					              
						              <p onClick = {function(){
						              	 	alert(project.np_app)
						              	 }} class = "nonprofit-table-text" style = {{textAlign:"center",whiteSpace:"normal",color:"black",fontSize:"1.3vw"}}>

						              	See App Description
						              </p>
					              </td>
					              <td class="nonprofit-table-text w-2/12 text-xs font-medium text-gray-500 uppercase tracking-wider">
						             <p style = {{textAlign:"center",textDecorationLine:"underline"}} onClick = {function(){
						              	window.open(app_builder_server + "?app_name=" + project.app_builder_project)
						              }} class = "nonprofit-table-text-image" style = {{textAlign:'center', whiteSpace:"normal", fontSize:"42px"}}> 📱
						              </p>
					              </td>
					              <td class="nonprofit-table-text w-2/12 text-xs font-medium text-gray-500 uppercase tracking-wider">
						              <p style = {{textAlign:"center", textDecorationLine:"underline"}} onClick = {function(){
						              	window.open(project.np_wireframe_link)
						              }} class = "nonprofit-table-text-image" style = {{textAlign:'center',whiteSpace:"normal",fontSize:"42px"}}> 📷
						              </p>
					              </td>
					              <td class="nonprofit-table-text w-2/12 text-xs font-medium text-gray-500 uppercase tracking-wider">
						             {!project.complete ? ( 
						             	<p style = {{textAlign:"center",textDecorationLine:"underline"}} onClick = {function(){
						              
						              if(window.is_teacher){

						              	if(window.confirm("Do you want to finish the app? Only do this if the app your children created is done and ready to give to the nonprofit")){

							              	var db_url = window.server + "/teachers_students?teacher=" + window.username
							
									        fetch(db_url, {
									                      method: 'GET',
									                      headers: {
									                        "Content-Type": "application/json",
									                        "Accept": "application/json"
									                      }
									            }).then(async function(res){
									     			console.log(res);
									              res = await res.json();
									             console.log(res);
									            
									              if(res.status === "Success"){
									              	window.RewardStudents.setState({
									              		students:res.data,
									              		np:project
									              	})
									              	window.reward_students_window.dialog( "open" );
									              	window.account_page.dialog('close');
									                return
									                
									              } else {
									                alert("Failed. Try again later.")
									              }
										        }).catch(function(e){
										          alert("Failed. Try again later.")
										        })
										   }
						              	


						              } else {
						              	alert("Create a great app for the nonprofit. When your teacher marks it done, you'll get 300 XP and win more prizes!")
						              	window.open(app_builder_server + "?f=true&n=" + project.phone_id);
						              }
						              	
						              }} class = "nonprofit-table-text-image" style = {{textAlign:'center',whiteSpace:"normal", fontSize:"42px"}}> 🥇
						              </p>):( 
						             	<p style = {{textAlign:"center",textDecorationLine:"underline"}} onClick = {function(){
						              
						         
						              	
						              }} class = "nonprofit-table-text-image" style = {{textAlign:'center',whiteSpace:"normal", fontSize:"42px"}}> ✔️
						              </p>)
						          }
					              </td>
					             
					            
					            </tr>
				          	)
				          })}
				            

				 
				          </tbody>
				        </table>
				      </div>
				    </div>
				  </div>
				</div>



				)
		} else {
			var content = ""
		}

		
		
		return (
			<div style = {{width:"100%"}}>
				<div style = {{width:"100%",height:"10%", backgroundColor:"grey",zIndex:1000}} class = "grid gap-4 grid-cols-3">
					 <div  style = {{alignItems:"center"}}>
						<button style = {{color:"white"}} onClick = {function(){
							that.setState.bind(that)({page:"account"})
						}}>Edit Account</button>
					</div>
					 <div  style = {{alignItems:"center"}}>
						<button style = {{color:"white"}}  onClick = {function(){
							that.setState.bind(that)({page:"nonprofits"})
						}}>Edit NonProfits</button>
					</div>
					{
						window.is_teacher ? (<div  style = {{alignItems:"center"}}>
						<button style = {{color:"white"}}  onClick = {function(){
							that.setState.bind(that)({page:"teacher"})
						}}>Teacher Info</button>
					</div>):null
					}

				</div>
				
			{content}

			</div>

			)
	}
}

export default AccountPage