import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ScrollView, Text, View, Animated } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';
import Select from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { FaCheck } from 'react-icons/fa';
import ArrowKeysReact from 'arrow-keys-react';
import TeacherView from './TeacherView';
import './Survey.css';
//development
var app_builder_server = "https://appbuilderdev.herokuapp.com"
const server = "https://streamedbook.herokuapp.com"
//production
//var app_builder_server = "https://calm-chamber-06785.herokuapp.com/"
// const server = "http://ec2-3-134-253-9.us-east-2.compute.amazonaws.com:3000"

class SurveyPage extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			submitted:false,
			how_much_did_you_like:5,
			how_well_did_you_understand:5,
			what_work_on_more:"",
			what_work_on_less:"",
			come_back_to_vac:"",
			personal_app_description:"",
			user_stats:{}

		}

	}

	componentDidMount(){
		window.surveypage = this;
		try{
			window.$("#surveydiv").parent().children(".ui-dialog-titlebar").empty().append(window.$("<p style = 'float:left'>Before you do this activity...answer questions to earn xp</p><img style = 'width:5%;float:left' src = '/xp.gif'></img>"))
			window.$("#surveydiv").parent().children(".ui-dialog-titlebar").css("background-color","navy");
			window.$("#surveydiv").parent().children(".ui-dialog-titlebar").css("color","white");
		} catch(e){
			console.log(e)
		}
	}

	reward(){
		var that = this;
		var d = new Date();
		var date_str = d.getTime().toString();
		var new_xp_val = !that.state.user_stats.xp ? 5: that.state.user_stats.xp + 5
		var update_obj = {
			xp:new_xp_val
		}

		try{

			if(that.state.user_stats.entered_email){
				update_obj["email"] = that.state.user_stats.entered_email
			}

			if(that.state.user_stats.entered_parent_email){
				update_obj["parent_email"] = that.state.user_stats.entered_parent_email
			}

			if(that.state.user_stats.entered_personal_app_description){
				update_obj["personal_app_description"] = that.state.user_stats.entered_personal_app_description
			}

		} catch(e){
			var update_obj = {
				xp:new_xp_val
			}
		}

		var body = {crud_operation:"update",
		table:"vac_users",
		args:[{
				username:that.state.user_stats.username
			},update_obj]
		}
		var db_url = server + "/generic_query";
          fetch(db_url, {
                        method: 'POST',
                        body:JSON.stringify(body),
                        headers: {
                          "Content-Type": "application/json",
                          "Accept": "application/json"
                        }
              }).then(async function(res){
                res = await res.json();
                if(res.status === "Success"){
                 alert("Thanks! You earned Xp")
                  that.setState({submitted:true},function(){
                  	window.appFrame.state.user_stats.xp = new_xp_val
                  	window.appFrame.setState({user_stats:window.appFrame.state.user_stats});
                  })

                   window.surveydiv.dialog('close')
                } else {
                  alert("We had an issue. Try again later to earn points.")
                  that.setState({submitted:true})
                  window.surveydiv.dialog('close')

                }
                
          }).catch(function(e){
            alert("We had an issue. Try again later to earn points.")
            that.setState({submitted:true})
            window.surveydiv.dialog('close')
          })
        

	}

	submitSurvey(){
		var that = this;
		var d = new Date();
		var date_str = d.getTime().toString();
		var new_xp_val = that.state.user_stats.xp ? 5: that.state.user_stats.xp + 5

		var body = {crud_operation:"insert",
		table:"student_surveys",
		args:[{
				how_much_did_you_like:that.state.how_much_did_you_like,
				how_well_did_you_understand:that.state.how_well_did_you_understand,
				what_work_on_more:that.state.what_work_on_more,
				what_work_on_less:that.state.what_work_on_less,
				username:that.state.user_stats.username,
				teacher:that.state.user_stats.teacher,
				xp:new_xp_val,
				date_str: date_str,
				school: that.state.user_stats.school,
				email: that.state.user_stats.email,
				parent_email: that.state.user_stats.parent_email,
				come_back_to_vac: that.state.come_back_to_vac

			}]
		}
		var db_url = server + "/generic_query";
          fetch(db_url, {
                        method: 'POST',
                        body:JSON.stringify(body),
                        headers: {
                          "Content-Type": "application/json",
                          "Accept": "application/json"
                        }
              }).then(async function(res){
                res = await res.json();
                if(res.status === "Success"){
                 that.reward.bind(that)()
                } else {
                  alert("We had an issue. Try again later to earn points.")
                  that.setState({submitted:true})
                  window.surveydiv.dialog('close')

                }
                
          }).catch(function(e){
            alert("We had an issue. Try again later to earn points.")
            that.setState({submitted:true})
            window.surveydiv.dialog('close')
          })
        

	}

	run(){
		alert('ays')
	}

	render(){
		var that = this;
		if(!this.state.submitted){
		return (
		<div>
			<header>
			  <h1 id="title">...with xp you can earn more coding opportunities</h1>
			 	<br></br>
			 </header>
			<main style = {{color:'black'}}>
			 	
			    <div class="form-group">
			      <label for="how_much_did_you_like">How engaging was today's class?</label>
			     <br></br>
			      <select onChange = {function(event){
			      	that.setState({how_much_did_you_like: parseInt(event.target.value)});
			      }} value = {this.state.how_much_did_you_like}>
			      	<option value="1">1</option>
			      	<option value="2">2</option>
			      	<option value="3">3</option>
			      	<option value="4">4</option>
			      	<option value="5">5</option>
			      </select>

			    </div>
			    <div class="form-group">
			      <label for="how_well_did_you_understand">How well did you understand today's class material?</label>
			     <br></br>
			      <select onChange = {function(event){
			      	that.setState({how_well_did_you_understand: parseInt(event.target.value) });
			      }} value = {that.state.how_well_did_you_understand}>
			      	<option value="1">1</option>
			      	<option value="2">2</option>
			      	<option value="3">3</option>
			      	<option value="4">4</option>
			      	<option value="5">5</option>
			      </select>

			    </div>

			     <div  class="form-group">
			      <label for="what_work_on_more">What was the best part of class and one activity you want to spend more time on in future classes? </label>
			     <br></br>
			      <textarea type="text"   onChange = {function(event){
			      	that.setState({what_work_on_more: event.target.value});
			      }} name = "what_work_on_more" class="form-control" value={that.state.what_work_on_more}
			      rows="1"></textarea>

			    </div>

			     <div class="form-group">
			      <label for="what_work_on_less">Was there an activity you did not enjoy or would like to spend less time on in future classes? </label>
			     <br></br>
			      <textarea
			      type="text"
			      name = "what_work_on_less}" 
			       onChange = {function(event){
			      	that.setState({what_work_on_less: event.target.value});
			      }} class="form-control"  value = {that.state.what_work_on_less} rows="1"></textarea>

			    </div>

			    <div class="form-group">
			      <label for="what_work_on_less">Do you want to stay in this club and learn more code?</label>
			     <br></br>
			      <textarea
			      type="text"
			      name = "come_back_to_vac" 
			       onChange = {function(event){
			      	that.setState({come_back_to_vac: event.target.value});
			      }} class="form-control"  value = {that.state.come_back_to_vac} rows="1"></textarea>

			    </div>
			    {
			    	!that.state.user_stats.email ?
			    	(

			    <div class="form-group">
			      <label for="what_work_on_less">It looks like we dont have an email for you. Enter your email</label>
			     <br></br>
			      <textarea
			      type="text"
			      name = "what_work_on_less}" 
			       onChange = {function(event){
			       	that.state.user_stats.entered_email = event.target.value;
			      	that.setState({user_stats: that.state.user_stats});
			      }} class="form-control"  value = {that.state.user_stats.entered_email} rows="1"></textarea>

			    </div>
			    	):null
			    }
			    {
			    	!that.state.user_stats.parent_email ?
			    	(

			    	<div class="form-group">
			      <label for="what_work_on_less">Enter an email for your parent</label>
			     <br></br>
			      <textarea
			      type="text"
			      name = "what_work_on_less}" 
			       onChange = {function(event){
			      	that.state.user_stats.entered_parent_email = event.target.value;
			      	that.setState({user_stats: that.state.user_stats});
			      }} class="form-control"  value = {that.state.user_stats.entered_parent_email} rows="1"></textarea>

			    </div>
			    	):null
			    }

			    {
			    	!that.state.user_stats.personal_app_description ?
			    	(

			    	<div class="form-group">
			      <label for="personal_app_description">Describe the personal app you chose to make, in a few sentences</label>
			     <br></br>
			      <textarea
			      type="text"
			      name = "personal_app_description" 
			       onChange = {function(event){
			      	that.state.user_stats.entered_personal_app_description = event.target.value;
			      	that.setState({user_stats: that.state.user_stats});
			      }} class="form-control"  value = {that.state.user_stats.entered_personal_app_description} rows="1"></textarea>

			    </div>
			    	):null
			    }
			    
			    
			    
			    
			    
			    <button class="btn btn-primary mb-2" onClick = {this.submitSurvey.bind(this)}>Send</button>
			 
			</main>
		</div>
		)
		}

		 return (
		 <div>
		 	<p>Thanks!</p>
		 </div>


		 )
	}

}


export default SurveyPage;