import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AccountPage from './AccountPage';
import SurveyPage from './SurveyPage';
import AppStatsScreen from './AppStatsScreen';
import RewardStudents from './RewardStudents';
import * as serviceWorker from './serviceWorker';

window.app = {
      appProjectSelect:function(){
        
      },
      codingLanguageSelect:function(){

      }, 
      practiceActivitiesSelect:function(){

      }
    }

ReactDOM.render(
  <React.StrictMode>
    <AccountPage />
  </React.StrictMode>,
  document.getElementById('account_page')
);

ReactDOM.render(
  <React.StrictMode>
    <RewardStudents />
  </React.StrictMode>,
  document.getElementById('reward_students_div')
);

ReactDOM.render(
  <React.StrictMode>
    <SurveyPage />
  </React.StrictMode>,
  document.getElementById('surveydiv')
);

ReactDOM.render(
  <React.StrictMode>
    <AppStatsScreen />
  </React.StrictMode>,
  document.getElementById('appscreen')
);





ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


   window.$('#drawerOpener').click(function() {
      window.$(".drawer").toggleClass("drawer-closed",'normal');
      return false;
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
window.x = 0;
 

