import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ScrollView, Text, View, Animated } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';
import Select from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { FaCheck } from 'react-icons/fa';
import ArrowKeysReact from 'arrow-keys-react';
import TeacherView from './TeacherView';
import './Survey.css';
//development
var app_builder_server = "https://appbuilderdev.herokuapp.com"
const server = "https://streamedbook.herokuapp.com"
//production
//var app_builder_server = "https://calm-chamber-06785.herokuapp.com/"
// const server = "http://ec2-3-134-253-9.us-east-2.compute.amazonaws.com:3000"

class AppStatsScreen extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			projects:[],
			skill: "elements",
			project_index:0
		}

	}

	componentDidMount(){
		window.appstatsscreen = this;
		window.$("#appscreen").css("background-color","#224761")
		
	}

	reward(){
		
	}

	submitSurvey(){
		
        

	}


	render(){
		var that = this;
		that.state.project = that.state.projects[that.state.project_index]

		if(that.state.project === undefined){
			return (<div></div>)
		}
		
		return (

		<div>

			<img style = {{overflow: "scroll", position: "absolute", left: "0", top: "0", zIndex: -10, height: "100%", width: "100%"}} src = "https://cdn.lowgif.com/full/d152eae70b1a9a4f-stacking-houses-on-a-windy-day-by-faxdoc-pixel-art.gif"/>

			<header style = {{width:"160%", marginTop:"1%", marginLeft:"2%", height:"20%", alignItems:"center", marginBottom:"2%"}}>
				

				<h1 style = {{color:"white", textTransform: "uppercase", font: "candara", textAlign:"center", fontSize:"40px", font: "Cambria"}}>Complete a project for {that.state.project.np_name}</h1>

				<div style = {{position: "relative", left: "40%"}}>
					<button 
						style = {{padding: "10px", cursor: "pointer", color: "white", backgroundColor: "#05904A", borderColor: "#05904A", borderRadius: "20px"}}
						onClick = {function(){
							that.setState({project_index: (that.state.project_index + 1) % that.state.projects.length })
						}}>SWITCH NON PROFITS
					</button>
				</div>
			
			</header>

				
		
			<div class = "grid grid-cols-3" style = {{zIndex:10}}>
				<div class="col-span-1" style = {{alignItems:"center", height:"100%"}}>


				<iframe height = "200px" width = "350px" src = {that.state.project.np_website.indexOf("https://") === -1 ? ("https://" + that.state.project.np_website):that.state.project.np_website}></iframe>

						<div class="bg-white shadow overflow-hidden sm:rounded-lg">

							  <div style = {{marginTop: "3px"}} class="border-t border-gray-200">
						    <dl>
							      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							        <dt class="b10InfoColumn">
							          Wireframe
							        </dt>
							        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							          <a onClick = {function(){
							          	window.open(that.state.project.np_wireframe_link)
							          }}> Click to see the Wireframe
							          </a>
							        </dd>
							      </div>
							      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							        <dt class="b10InfoColumn">
							          Mission
							        </dt>
							        <dd style = {{overflow:"scroll", height:"100px"}} class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							          {that.state.project.np_mission}
							        </dd>
							      </div>
							      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
							        <dt class="b10InfoColumn">
							          Type of App
							        </dt>
							        <dd style = {{overflow:"scroll", height:"100px"}}  class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
							          {that.state.project.np_app}
							        </dd>
							      </div>
							    </dl>
							  </div>
							</div>
					</div>
				<div style = {{marginLeft:"3%"}} class="col-span-2">
						<div style = {{height:"780px",overflow:"scroll"}} class="flex flex-col">
						  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						      <div  class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
						       
						        <table class="min-w-full divide-y divide-gray-200">
						          <thead class="bg-gray-50">
						            <tr>
						              <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
						                XP
						              </th>
						              <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
						                Skills
						              </th>
						              <th scope="col" class="px-5 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
						                Description
						              </th>
						              <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
						                Link
						              </th>
						            </tr>
						          </thead>
						          <tbody class="bg-white divide-y divide-gray-200">

						           
						            {that.state.project.tasks ? that.state.project.tasks.sort(function(taska,taskb){
						            	return taska.task_id - taskb.task_id 
						            }).filter(function(task){
						            	return task.vac_skill_1 === that.state.skill || task.vac_skill_2 === that.state.skill
						            }).map(function(task,ind){
						            	return (
						            <tr style = {{opacity: (task.vac_skill_1 !== that.state.skill && task.vac_skill_2 !== that.state.skill && task.vac_skill_3 !== that.state.skill) ? 0.5:1  }}>
						              <td style = {{width:"5%"}} class="px-1 py-4 whitespace-nowrap">
						                <div class="flex items-center" style = {{width:"100%", marginLeft:"0%"}}>
						                  <img style = {{width:"55%", float:"left"}} src = "/xp.gif"></img>
						                  <p>{Math.round((ind +1)*2.5)}</p>	
				
						                </div>
						              </td>
						              <td class="px-1 py-4 whitespace-nowrap">
						                <div  style = {{alignItems:"center"}}>
							                <p class="text-sm text-gray-900 text-center" style = {{width:"100px",whiteSpace:"pre-wrap"}}>
							                {task.vac_skill_2 ? task.vac_skill_1 + ", " + task.vac_skill_2 : task.vac_skill_1}
							                </p>
						                </div>
						              </td>
						              <td class="px-6 py-4 whitespace-nowrap">
						                <div>
						                <p class = "text-sm text-gray-900 text-center" style = {{whiteSpace:"pre-wrap",width:"350px"}}>
						                 	{task.task}
						                 </p>
						                </div>
						              </td>
						              <td class="px-1 py-4 whitespace-nowrap">
						                <div  style = {{alignItems:"center"}}
						                	onClick = {function(){
						                		console.log(task);
						                		//alert(app_builder_server + "?app_name=" + that.state.project.app_builder_project + "&student_name=" + window.student_name + "&task_id=" + task.task_id + "&task=" + task.task)
						                		window.topapp.setState({
									              page: "activity",
									              answers: [],
									              teacher_question: "",
									              address: app_builder_server + "?app_name=" + that.state.project.app_builder_project + "&student_name=" + window.student_name + "&task_id=" + task.task_id + "&task=" + task.task
									            },function(){
									            	window.appscreen.dialog('close')
									            });
								            }}

						                >
							                <p class="text-sm text-gray-900 text-center" style = {{width:"100px", whiteSpace:"pre-wrap", textDecoration: "underline", cursor: "pointer"}}>
							               	GO
							                </p>
						                </div>
						              </td>

						            </tr>

						            		)
						            }):null
						        }

						            
						          </tbody>
						        </table>
						      </div>
						    </div>
						  </div>
						</div>

				</div>
			</div>
			  
		</div>
		)
		

	}

}


export default AppStatsScreen;