import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ScrollView, Text, View, Animated } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';
import Select from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { FaCheck } from 'react-icons/fa';
import ArrowKeysReact from 'arrow-keys-react';
const server = "https://streamedbook.herokuapp.com"
//development
var app_builder_server = "https://appbuilderdev.herokuapp.com"

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const overall_curriculum = ""

var d = new Date()
var month = d.getMonth();
var day = d.getDate();
var letters = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];
var letters_obj = {};
letters.forEach(function(letter,i){letters_obj[i] = letter})
var stringMonth = month.toString().split("").map(function(ltr){
  return letters_obj[ltr]
}).join("")
var stringDay = day.toString().split("").map(function(ltr){
  return letters_obj[ltr]
}).join("")
window.vacpassword = "vac" + stringMonth + stringDay
var password = d.getDay();


 function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
  };  



function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

class TeacherView extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			students:[],
			schools:[],
			selected_school:"default",
			selected_student:"default",
      page:"students",
      selected_curriculum_step:{},
      partner:""
		}
	}

  overAllCurriculum(curriculum){
      var that = this;
    var design_current_skills = window.vac_skills.filter(function(skill){
      return skill.design_or_mobile === "design"
    })
     var coding_current_skills = window.vac_skills.filter(function(skill){
      return skill.design_or_mobile === "mobile"
    })
    return (
      <div>


        
        <p style = {{whiteSpace:"normal", color:"black"}}>
        Start off by doing the beginner portions of the curriculum, <span style = {{color:"orange"}}> in orange, </span> with students
        </p>
        <p style = {{color:"black",textDecorationLine:"underline", marginBottom:"1%",marginTop:"1%"}}>
        At the end of each class, suggest what students should work on before the next class... there is a HW guide below the table.
        </p>

        <div style = {{overflow:"scroll"}} class = "grid-cols-2">
          <div style = {{width:"50%", overflow:"scroll", float:"left"}}>
            <p style = {{textAlign:"center", fontSize:"28px", marginTop:"5%"}}>Design Curriculum</p>
          
          <table style = {{width:"50%"}} class="table-auto divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <p>  Video <span style = {{fontSize:"6px"}}> PW: teacherpassword</span></p>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    {design_current_skills.map(function(skill){
                      return (<tr>
                           <td class="px-4 py-4 whitespace-wrap">
                             <p style = {{fontSize:"10px", color:"black"}}>
                              { !skill.beginner_level ? skill.name:(<span style = {{color:"orange"}}><span style = {{color:"purple"}}>{skill.beginner_level + ":"}</span> {skill.name}</span>)}
                              </p>
                           </td>
                           <td class="px-4 py-4 whitespace-wrap">
                           <p style = {{fontSize:"10px", color:"black"}}>
                            {skill.step_type}
                            </p>
                           </td>
                           <td class="px-4 py-4 whitespace-wrap">
                           <p style = {{fontSize:"10px", color:"black"}}>
                            {skill.teacher_tips}
                            </p>
                           </td>
                           <td class="px-4 py-4 whitespace-wrap">
                           <p style = {{fontSize:"10px", color:"black"}}>
                            {skill.video_link}
                            </p>
                           </td>
                        </tr>
                        )
                    })}
                    
                  </tbody>
                </table>
          </div>
          <div style = {{width:"50%", float:"left"}}>
            <p style = {{textAlign:"center",fontSize:"28px", marginTop:"5%"}}>Coding Curriculum</p>
              <table style = {{width:"50%"}} class="table-auto divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description

                      </th>
                      <th scope="col" class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <p>  Video <span style = {{fontSize:"6px"}}> PW: teacherpassword</span></p>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    {coding_current_skills.map(function(skill){
                      return (<tr>
                           <td class="px-4 py-4 whitespace-wrap">
                             <p style = {{fontSize:"10px", color:"black"}}>
                              { !skill.beginner_level ? skill.name:(<span style = {{color:"orange"}}><span style = {{color:"purple"}}>{skill.beginner_level + ":"}</span> {skill.name}</span>)}
                              </p>
                           </td>
                           <td class="px-4 py-4 whitespace-wrap">
                           <p style = {{fontSize:"10px", color:"black"}}>
                            {skill.step_type}
                            </p>
                           </td>
                           <td class="px-4 py-4 whitespace-wrap">
                           <p style = {{fontSize:"10px", color:"black"}}>
                            {skill.teacher_tips}
                            </p>
                           </td>
                           <td class="px-4 py-4 whitespace-wrap">
                           <p style = {{fontSize:"10px", color:"black"}}>
                            {skill.video_link}
                            </p>
                           </td>
                        </tr>
                        )
                    })}
                    
                  </tbody>
                </table>

          </div>
        </div>
        <p>

        After DAY 1 (covered HTML) -> Students should work on their personal websites, creating a site that expresses their personality. Do a quick show and tell of these at the start of next class!
      
        </p>
        <p>
          After DAY 2/3 (HTML/JS) -> Students should start doing the Practice Activity, HTML Practice. Let them know this will increase their level!
        
        </p>
        <p>
        After DAY 2/3 (JS) -> Students should start doing the Practice Activity, JS Practice. Let them know this will increase their level!
       
        </p>
        <p>
         All subsequent days -> Students should refine their personal apps between every session. At the end of the session, make sure they know what specific feature they want to try to add to their app this week. Check in with them on it the next week.

        </p>

      </div>

      )
  }


  gemClick(skill){
    var that = this; 
    alert(skill.link)
    switch(skill.link) {
      case "pairprogramming":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: "/pairprogramming.jpg"
        });
        window.Cookies.set('vineyardappcampindex', 0);
        window.current_key_name = "how_to_html_button";
        // code block
        break;
      case "website":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: "https://secret-dusk-20902.herokuapp.com/pizzaexample?student_name=" + window.student_name
        });
        window.Cookies.set('vineyardappcampindex', 0);
        window.current_key_name = "how_to_html_button";
        // code block
        break;
      case "html_editor":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: "https://vacanswerserver.herokuapp.com/coding_tool?room=" + window.class_room + "&language=html"
        });
        window.Cookies.set('vineyardappcampindex', 0);
        window.current_key_name = "how_to_html_button";
        // code block
        break;
      case "js_editor":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: "https://vacanswerserver.herokuapp.com/coding_tool?room=" + window.class_room
        });

        window.Cookies.set('vineyardappcampindex', 0);
        window.current_key_name = "how_to_html_button";
        // code block
        break;
      case "website":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: "https://secret-dusk-20902.herokuapp.com/pizzaexample?student_name=" + window.student_name
        });

        window.Cookies.set('vineyardappcampindex', 0);
        window.current_key_name = "how_to_html_button";
        // code block
        break;
      case "inspecting":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: "https://www.wikipedia.com"
        })
        break;
      case "personal_app_builder":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: app_builder_server + "?personal_app_mode=true&student_name=" + window.student_name
        })
        break;
      case "personal":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: app_builder_server + "?personal_app_mode=true&student_name=" + window.student_name
        })
        break;
      case "servers":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: "https://secret-dusk-20902.herokuapp.com/csd"
        });

        window.Cookies.set('vineyardappcampindex', 0);
        window.current_key_name = "how_to_html_button";
        // code block
        break;
      case "app_builder":
        window.topapp.setState.bind(window.topapp)({
          page: "activity",
          answers: [],
          teacher_question: "",
          address: app_builder_server
        });
        window.current_key_name = "why_appbuilder";
        // window.socket.emit("page_change", {
        //   username: window.first_name, room: window.class_room, page: "why_appbuilder"
        // })



        window.Cookies.set('vineyardappcampindex', 9);
        break;
      default:
        // window.open(app_builder_server + "?challenge_mode=" + skill.name.replaceAll(" ","%20") + "&student_name=" + window.student_name)
        that.state.selected_skill = skill;
        if(skill.step_type === "challenge"){
          window.topapp.setState.bind(window.topapp)({
            page: "activity",
            answers: [],
            teacher_question: "",
            address: app_builder_server + "?challenge_mode=" + replaceAll(skill.name," ","%20") + "&student_name=" + window.student_name
          });
          window.current_key_name = "why_appbuilder";
        } 
        else if (skill.step_type === "personal"){
          window.topapp.setState.bind(window.topapp)({
            page: "activity",
            answers: [],
            teacher_question: "",
            address: app_builder_server + "?personal_app_mode=true&student_name=" + window.student_name
          });
          window.current_key_name = "why_appbuilder";
        } else {
          that.getContributionBySkill.bind(that)(skill)
        }
    }
  }

	componentDidMount(){
		window.TeacherView = this;
	}

  addPartner(student_1,student_2, student_index){
        var that = this;
        var db_url = server + "/set_partner?student_1=" + student_1+"&student_2=" + student_2;
        fetch(db_url, {
                      method: 'GET',
                      headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                      }
            }).then(async function(res){
             
             try{
              console.log(res);
              res = await res.json();
              if(res.status === "Success"){
                window.teachers_students[student_index].partner = student_2;
                that.forceUpdate();
                return alert("Done!")
              } else {
                alert("Failed. Try again later.")
              }
            } catch(e){
              alert(e)
            }
        }).catch(function(e){
              alert(e)
            })

  }

	render_skills(student_accomplishment_string, design_or_coding){

	var that = this;
    var level_names = {
      1:"TIN",
      2:"BRONZE",
      3:"SILVER",
      4:"GOLD",
      5:"DIAMOND",
      6:"PLATINUM",
      7:"MEGA-PLATINUM"
    }

    var level_colors = {
       1:"#E6B400",
      2:"BRONZE",
      3:"SILVER",
      4:"GOLD",
      5:"DIAMOND",
      6:"PLATINUM",
      7:"MEGA-PLATINUM"
    }

    var learning_skills = window.vac_skills.filter(function(skill){
      return skill.step_type === "learning"
    })

    var design_current_skills = window.vac_skills.filter(function(skill){
    	return skill.design_or_mobile === "design"
    })
     var coding_current_skills = window.vac_skills.filter(function(skill){
    	return skill.design_or_mobile === "mobile"
    })

      var design_class = "grid gap-4 grid-cols-" + design_current_skills.length
      var coding_class = "grid gap-4 grid-cols-"+ coding_current_skills.length

      if(design_current_skills.length === 1){
        var design_class = "grid gap-4 grid-cols-1"
      }

       if(design_current_skills.length === 2){
        var design_class = "grid gap-4 grid-cols-2"
      }

       if(design_current_skills.length === 3){
        var design_class = "grid gap-4 grid-cols-3"
      }

       if(design_current_skills.length === 4){
        var design_class = "grid gap-4 grid-cols-4"
      }

       if(design_current_skills.length === 5){
        var design_class = "grid gap-4 grid-cols-5"
      }

       if(design_current_skills.length === 6){
        var design_class = "grid gap-4 grid-cols-6"
      }

       if(design_current_skills.length === 7){
        var design_class = "grid gap-4 grid-cols-7"
      }

       if(design_current_skills.length === 8){
        var design_class = "grid gap-4 grid-cols-8"
      }

       if(design_current_skills.length === 9){
        var design_class = "grid gap-4 grid-cols-9"
      }

       if(design_current_skills.length === 10){
        var design_class = "grid gap-4 grid-cols-10"
      }







      if(coding_current_skills.length === 1){
        var coding_class = "grid gap-4 grid-cols-1"
      }

       if(coding_current_skills.length === 2){
        var coding_class = "grid gap-4 grid-cols-2"
      }

       if(coding_current_skills.length === 3){
        var coding_class = "grid gap-4 grid-cols-3"
      }

       if(coding_current_skills.length === 4){
        var coding_class = "grid gap-4 grid-cols-4"
      }

       if(coding_current_skills.length === 5){
        var coding_class = "grid gap-4 grid-cols-5"
      }

       if(coding_current_skills.length === 6){
        var coding_class = "grid gap-4 grid-cols-6"
      }

       if(coding_current_skills.length === 7){
        var design_class = "grid gap-4 grid-cols-7"
      }

       if(coding_current_skills.length === 8){
        var design_class = "grid gap-4 grid-cols-8"
      }

       if(coding_current_skills.length === 9){
        var design_class = "grid gap-4 grid-cols-9"
      }

       if(coding_current_skills.length === 10){
        var design_class = "grid gap-4 grid-cols-10"
      }
      


     
      var design_class = "grid gap-1 grid-cols-12 grid-rows-1"
    
      design_current_skills = design_current_skills.sort(function(a,b){
      	 if(a.level === b.level){
      	 	return a.level_order - b.level_order
      	 }
	      return a.level - b.level
	    })  



        coding_current_skills = coding_current_skills.sort(function(a,b){
      	 if(a.level === b.level){
      	 	return a.level_order - b.level_order
      	 }
	      return a.level - b.level
	    })  

    if(design_or_coding){
      var current_skills = design_current_skills;
      var skills_class = design_class
    } else {
      var current_skills = coding_current_skills;
      var skills_class = coding_class;
    }

		return (
			 <div style = {{height:"100px",overflow:"scroll"}} class="grid gap-1 grid-cols-1">
			 	<div  style = {{float:"left", overflow:"scroll", alignItems:'center', marginTop:"0%",  marginLeft:"0%","width":"100%",height:"90%"}}>
			 		{<p style = {{color:"black", marginTop:"40%"}}> {design_or_coding ? "DESIGN>":"CODING>"} </p>}
			 	</div>
        {
                  current_skills.map(function(skill){

                  
                  	
                    return student_accomplishment_string.split(",").indexOf(skill.name) !== -1 ? (
                <div  style = {{float:"left", alignItems:'center', marginTop:"0%",  marginLeft:"0%","width":"100%",height:"6%"}}>
                  <p onClick = {function(){
                    var specials = ["html_editor","js_editor","website","pairprogramming", "inspecting","servers","app_builder"];
                    if(specials.indexOf(skill.link) === -1){
                      return that.gemClick.bind(that)(skill);
                    }

                    var d = new Date();

                    if(window.is_teacher){
                      alert("Welcome, teacher. The password for this gem, based on the current minute, is vac" + d.getMinutes());
                    }


                    if(prompt('This is a special gem, requiring a teacher. What is the password?') !== ('vac' + d.getMinutes())){
                      return alert("That is not the correct password!");
                    }


                   that.finishStep.bind(that)(skill,function(){
                    that.setState({vac_skills:that.state.vac_skills, skills_string: that.state.skills_string},function(){
                          that.gemClick.bind(that)(skill);
                        });
                   });    


                    
                  }} style = {{fontSize:"8px", wordWrap: "break-word", whiteSpace: "normal",textAlign:"center", width:"100%",wordWrap: "break-word",  color:"green"}}><span style = {{fontWeight:"bold"}}>{toTitleCase(skill.step_type)+ ": "}</span> {"\n" + skill.name}</p>
                </div>
                )
                :
                (
                 <div  style = {{float:"left", alignItems:'center', "width":"100%", marginLeft:"0%",  marginTop:"0%",height:"6%"}}>
                  <p onClick = {function(){
                    var specials = ["html_editor","js_editor","website","pairprogramming", "inspecting","servers","app_builder"];
                    if(specials.indexOf(skill.link) === -1){
                      return that.gemClick.bind(that)(skill);
                    }

                    var d = new Date();

                    if(window.is_teacher){
                      alert("Welcome, teacher. The password for this gem, based on the current minute, is vac" + d.getMinutes());
                    }


                    if(prompt('This is a special gem, requiring a teacher. What is the password?') !== ('vac' + d.getMinutes())){
                      return alert("That is not the correct password!");
                    }


                   that.finishStep.bind(that)(skill,function(){
                    that.setState({vac_skills:that.state.vac_skills, skills_string: that.state.skills_string},function(){
                          that.gemClick.bind(that)(skill);
                        });
                   });    


                    
                  }}  style = {{fontSize:"8px",whiteSpace: "normal",  textAlign:"center", color:"black",wordWrap: "break-word" }}> <span style = {{fontWeight:"bold"}}>{toTitleCase(skill.step_type)+ ": "}</span> {"\n" + skill.name}</p>
                </div>

                )


                  })

                }
               
              </div>



		)



	}


  getContributionBySkill(skill){
    var that= this;

    
     var db_url = server + "/get_contribution_by_skill?skill=" + skill.link;
    fetch(db_url, {
                method: 'GET',
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json"
                }
      }).then(async function(res){
        res = await res.json();
        if(res.status === "Failure"){
          return alert("There was an issue. Try again later.");
        }

        console.log(res);

        var contribution = res.contribution;

        if(typeof contribution === "string"){
          contribution = JSON.parse(contribution)
        }

         window.topapp.setState({
              page: "activity",
              answers: [],
              teacher_question: "",
              address: app_builder_server + "?app_name=" + contribution.np_name + "&student_name=" + window.student_name + "&task_id=" + contribution.id + "&task=" + contribution.task
            });
          window.current_key_name = "why_appbuilder";

           
        
       

    }).catch(function(e){
      console.log(e)
       return alert("There was an issue. Try again later.");

    })

  }

	studentTable(){
		var that = this;
		if(window.teachers_students === undefined){
			window.teachers_students = [];
		}

		return (

			<div class="flex flex-col">
				  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
				        <table style = {{width:"1200px"}} class="table-fixed divide-gray-200">
				          <thead class="bg-gray-50">
				            <tr>
				              <th scope="col" class="w-2/12 py-1  text-xs font-medium text-gray-500 uppercase tracking-wider">
				               <p style = {{textAlign:"center"}}>
                        Name
                        </p>
				              </th>
                      <th scope="col" class="w-1/12 py-1  text-xs font-medium text-gray-500 uppercase tracking-wider">
                       <p style = {{textAlign:"center"}}>
                        Partner
                        </p>
                      </th>
				              <th scope="col" class="w-1/12 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
				               <p style = {{textAlign:"center"}}> School</p>
				              </th>
				              <th scope="col" class="w-2/12 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
				                <p style = {{textAlign:"center"}}>Design Skills</p>
				              </th>
                      <th scope="col" class="w-2/12 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <p style = {{textAlign:"center"}}>Coding Skills</p>
                      </th>
				              <th scope="col" class="w-1/12 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
				                <p style = {{textAlign:"center"}}>Exercises</p>
				              </th>
				              <th scope="col" class="w-1/12 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
				                <p style = {{textAlign:"center"}}>App</p>
				              </th>
				             
				            </tr>
				          </thead>
				          <tbody class="bg-white divide-y divide-gray-200">
				          {window.teachers_students.filter(function(student,ind){
				          	if(that.state.selected_school === "default"){
				          		return true
				          	}
				          	return student.school === that.state.selected_school
				          }).map(function(student,ind){
				          	return (
				          		<tr style = {{marginLeft:"5%"}}>
				          		 <td class="w-2/12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
					              	<p class = "text-left" style = {{whiteSpace:"normal", marginLeft:"5%"}}>
					              		{student.username} 
					              	</p>
					              	<p id = {"student_link_"+student.phone_id}  onClick = {function(){
					              		window.open("https://tinyurl.com/vacpl?s=" + student.phone_id)
					              	}} class = "text-left" style = {{whiteSpace:"normal",fontSize:"8px", marginLeft:"5%"}}>
					              		(Link: {"https://tinyurl.com/vacpl?s=" + student.phone_id} )
					              	</p>
                          <button 
                          style = {{marginLeft:"5%"}}
                          onClick = {function(){
                            window.copyElementText("student_link_" + student.phone_id); 
                          }}>
                          Copy Student Link to Clipboard
                          </button>
					              </td>
                          <td class="w-2/12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <p class = "text-left" style = {{textAlign:"center", whiteSpace:"normal", marginLeft:"0%"}}>
                          {student.partner ? ("Partner: " + student.partner):"No partner"} 
                          </p>
                          <br></br>
                          <br></br> 
                          <p style = {{textAlign:"center", textDecorationLine:"underline"}}>Alter Partner</p>
                          <select onChange = {function(b){
                            that.addPartner.bind(that)(student.username,b.target.value, ind)
                          }}>
                            <option value = {"default"}>Default</option>
                            {window.teachers_students.map(function(student){
                              return (<option value = {student.username}>{student.username}</option>)
                            })}
                          </select>
                        </td>
					              <td class="w-1/12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
					              	<p style = {{whiteSpace:"normal",textAlign:"center"}}>
					              		{student.school}
					              	</p>
					              </td>
					              <td  class="w-2/12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
					              	
					              		{that.render_skills.bind(that)(student.student_accomplishment_string ? student.student_accomplishment_string.student_accomplishment_string:"", true)}
					              
					              </td>
                         <td  class="w-2/12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          
                            {that.render_skills.bind(that)(student.student_accomplishment_string ? student.student_accomplishment_string.student_accomplishment_string:"", false)}
                        
                        </td>
					              <td class="w-2/12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
						             
						              <div  style = {{alignItems:"center"}}>
						                <p onClick = {function(){
						                window.open("https://secret-dusk-20902.herokuapp.com/htmlexample?student_name=" + window.student_name)
						               }} 
                           id = "completion_percentage" 
                            style = {{width:"100%",textAlign:"center",fontSize:"11px",color:"red",position:'relative',display:"inline-block"}} >{student.html_button_progress ? student.html_button_progress + "% ":0 + "% " + "HTMLTest"}</p>
						       
						                
						              </div>

						             <div style = {{alignItems:"center"}}>
						                <p onClick = {function(){
						                window.open("https://secret-dusk-20902.herokuapp.com/day1?student_name=" + window.student_name)
						               }} id = "completion_percentage"  
                           style = {{width:"100%",textAlign:"center",fontSize:"11px",color:"red",position:'relative',display:"inline-block"}} >{student.js_button_progress ? student.js_button_progress + "% ":0 + "% " +  "JS Practice"}</p>
						                
						                    
						            
						              </div>

						             

						               


						              <div style = {{alignItems:"center"}}>
						      
						                <p 
                            onClick = {function(){
						                window.open("https://secret-dusk-20902.herokuapp.com/pizzaexample?student_name=" + student.username)
						               }} 
                           style = {{width:"100%",textAlign:"center", width:"100%", fontSize:"11px",color:"#775317",textDecorationLine:"underline",display:"inline-block", position:'relative', textAlign:"center"}}>   {"Website"}</p>

						              </div>

						             







					              </td>
					              <td class="w-1/12 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
						             <p onClick = {function(){
						             	window.open(app_builder_server + "?personal_app_mode=true&student_name=" + student.username)
						             }} style = {{width:"100%",textAlign:"center",whiteSpace:"normal"}}> 
						             Go
						              </p>
					              </td>
					             
					             
					            
					            </tr>
				          	)
				          })}
				            

				 
				          </tbody>
				        </table>
				      </div>
				    </div>
				  </div>
				</div>



			)





	}

	render(){
		var that = this;  
    if(window.teachers_students === undefined){
      window.teachers_students = [];
    }

    if(that.state.page === "overall"){
      return that.overAllCurriculum.bind(that)()
    }

    if(that.state.page === "add_students"){
      if(window.teachers){
        try{
         var teacher_id = window.teachers.filter(function(teacher){
          return teacher.first_name === window.username.split("_")[0] && teacher.last_name === window.username.split("_")[1]
         })

         teacher_id = teacher_id[0].teacher_id;
         var url =  "tinyurl.com/vacmake?t=" + teacher_id + "&u=STUDENT_NAME_HERE&sn=SCHOOL_NAME_HERE"
         var login_url = "tinyurl.com/vacdev/create?t="+ teacher_id 
       } catch(e){

        var url =  "tinyurl.com/vacmake?t=" + window.username + "&student_name=STUDENT_NAME_HERE&school_name=SCHOOL_NAME_HERE"
        var login_url = "Login url not available; use url login below"
        console.log(e);
       }


      } else {
        var url =  "tinyurl.com/vacmake?t=" + window.username + "&student_name=STUDENT_NAME_HERE&school_name=SCHOOL_NAME_HERE"
        var login_url = "Login url not available; use url login below"
         
      }
     
        return (
        <div id = "addstudentbox" style = {{alignItems:"center"}}>
          <div style = {{alignItems:"center"}} class = "grid grid-cols-5">
             <button style = {{borderRadius:"20px", backgroundColor:"grey",width:"100%",color:"white"}} 
                  style = {{backgroundColor:"#2ecc71", borderRadius:"10px", color:"white", height:"50%", boxShadow: "0px 4px rgba(0, 0, 0, 0.2)",
        margin: "0 7px 11px 0"}}
                  onClick = {function(){
                  that.setState({page:"students"})
                }}>See student details</button>
            <button style = {{borderRadius:"20px",  backgroundColor:"grey",width:"90%",  color:"white"}}
              style = {{backgroundColor:"#2ecc71", marginLeft:"3%",borderRadius:"10px", color:"white", height:"50%", boxShadow: "0px 4px rgba(0, 0, 0, 0.2)",
    margin: "0 7px 11px 0"}}

              onClick = {function(){
              that.setState({page:"See curriculum"})
            }}>See curriculum details</button>
            
            <p style = {{color:'black', whiteSpace:"pre"}}>Teacher Password Today: {window.vacpassword}</p>
          </div>
          <p style = {{textAlign:"center", marginTop:"5%", fontSize:"18px"}}>
          Your teacher create account page (have students go to this link to create a new account with you):
          </p>
          <p id = "student_create_account_link" style = {{fontWeight:"bold",textAlign:"center", marginTop:"5%", fontSize:"22px"}}>
         {login_url} </p>
          <p>
          </p>
          
        </div>

        )


    }


   
    if(that.state.page === "See curriculum"){
      return (
      <div>
        <div class = "grid grid-cols-4">
          <select  style = {{borderRadius:"10px", backgroundColor:"#2ecc71", width:"100%", color:"white"}} value = {(that.state.selected_curriculum_step && that.state.selected_curriculum_step.name) ? that.state.selected_curriculum_step.name:"Curriculum At A Glance (+ HW)" } onChange = {function(b){
            if(b.target.value === "Design At A Glance (+ HW)"){
              return that.setState.bind(that)({selected_curriculum_step:{
                name: "Design At A Glance (+ HW)"
              }})
            }

            if(b.target.value === "overall"){
              return that.setState.bind(that)({page:"overall"})
            }
            that.setState.bind(that)({
              selected_curriculum_step:
              window.vac_skills.filter(function(skill){
                return skill.name === b.target.value
              })[0]
            });
          }} id = "curriculum_step_select">

            <option value = {"default"}>See Design Curriculum</option>
            <option value = {"overallen"}>See Overall Curriculum</option>
            {window.vac_skills.sort(function(a,b){
              if(a.level === b.level){ return a.level_order - b.level_order}
                return a.level-b.level
            }).map(function(skill){
              if(skill.design_or_mobile !== "design"){return}
              return (<option value = {skill.name}>{skill.name}</option>)
            })}
          </select>
          <select  style = {{borderRadius:"10px", backgroundColor:"#2ecc71", width:"100%", color:"white"}} value = {(that.state.selected_curriculum_step && that.state.selected_curriculum_step.name) ? that.state.selected_curriculum_step.name:"Curriculum At A Glance (+ HW)" } onChange = {function(b){
            if(b.target.value === "Coding Curriculum At A Glance (+ HW)"){
              return that.setState.bind(that)({selected_curriculum_step:{
                name: "Coding Curriculum At A Glance (+ HW)"
              }})
            }

            if(b.target.value === "overall"){
              return that.setState.bind(that)({page:"overall"})
            }

            that.setState.bind(that)({
              selected_curriculum_step:
              window.vac_skills.filter(function(skill){
                return skill.name === b.target.value
              })[0]
            });
          }} id = "curriculum_step_select">
            <option value = {"default"}>See Javascript Curriculum</option>
            <option value = {"overallen"}>See Overall Curriculum</option>
            {window.vac_skills.sort(function(a,b){
              if(a.level === b.level){ return a.level_order - b.level_order}
                return a.level-b.level
            }).map(function(skill){
              if(skill.design_or_mobile !== "mobile"){return}
              return (<option value = {skill.name}>{skill.name}</option>)
            })}
          </select>
          <button style = {{borderRadius:"20px", backgroundColor:"grey",width:"100%",color:"white"}} 
            style = {{backgroundColor:"#2ecc71", borderRadius:"10px", color:"white", height:"50%", boxShadow: "0px 4px rgba(0, 0, 0, 0.2)",
  margin: "0 7px 11px 0"}}
            onClick = {function(){
            that.setState({page:"students"})
          }}>See student details</button>
          
          <p style = {{color:'black', whiteSpace:"pre"}}>Teacher Password Today: {window.vacpassword}</p>
        </div>
        
        {
that.state.selected_curriculum_step && that.state.selected_curriculum_step.teacher_info !== undefined ? 
(<p style = {{whiteSpace:"pre-line", width:"900px", marginTop:"2%"}}>
                  {that.state.selected_curriculum_step.teacher_info}
                </p>): that.overAllCurriculum.bind(that)()

        }
        
      </div>)
    }

		return (
			<div>
				<div class = "grid grid-cols-5">
          <button style = {{height:"50%"}} onClick = {function(){
            that.setState({page:"add_students"})
          }} class="um-cta pulse">Add Students</button>
					<select style = {{borderRadius:"10px",
           marginRight:"100px", backgroundColor:"#2ecc71", 
           width:"100%", color:"white",height:"50%"}} 
           value = {that.state.selected_school} onChange = {function(b){
						that.setState.bind(that)({selected_school:b.target.value});
					}} id = "selected_school_select">  
						<option value = {"default"}>Filter Students By School</option>
						{window.teachers_students.map(a => a.school).filter(onlyUnique).filter(a=> a!== null).map(a=>(<option value = {a}>{a}</option>))}
					</select>
          <button style = {{borderRadius:"20px",  backgroundColor:"grey",width:"90%",  color:"white"}}
            style = {{backgroundColor:"#2ecc71", marginLeft:"3%",borderRadius:"10px", color:"white", height:"50%", boxShadow: "0px 4px rgba(0, 0, 0, 0.2)",
  margin: "0 7px 11px 0"}}

            onClick = {function(){
            that.setState({page:"See curriculum"})
          }}>See curriculum details</button>
          
          <p style = {{color:'black', whiteSpace:"pre"}}>Teacher Password Today: {window.vacpassword}</p>
				</div>
				{that.studentTable.bind(that)()}
				
			</div>

			)
	}

}

export default TeacherView