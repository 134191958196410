import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ScrollView, Text, View, Animated } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';
import Select from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { FaCheck } from 'react-icons/fa';
import ArrowKeysReact from 'arrow-keys-react';
import TeacherView from './TeacherView';
import './Survey.css';
//development
var app_builder_server = "https://appbuilderdev.herokuapp.com"
const server = "https://streamedbook.herokuapp.com"
//production
//var app_builder_server = "https://calm-chamber-06785.herokuapp.com/"
// const server = "http://ec2-3-134-253-9.us-east-2.compute.amazonaws.com:3000"

class RewardStudents extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			students:[],
			selected_students:[],
			np:null
		}
		window.RewardStudents = this;
	}

	componentDidMount(){
			
	}

	submit(){
		var that = this;
		if(that.state.selected_students.length < 1){
			return alert("Select some students to reward before clicking this");
		}

		  var db_url = window.server + "/reward_students";
		  var body = {
		  	students:that.state.selected_students,
		  	phone_id:that.state.np.phone_id
		  }

          fetch(db_url, {
                        method: 'POST',
                        body:JSON.stringify(body),
                        headers: {
                          "Content-Type": "application/json",
                          "Accept": "application/json"
                        }
              }).then(async function(res){
                res = await res.json();
                if(res.status === "Success"){
                  alert("OK! Rewarded students! Congratulate your students")
                 window.reward_students_window.dialog( "close" );
                  } else {
                  alert("We had an issue. Try again later to see your prizes list.")
               		window.reward_students_window.dialog( "close" );
                }
                
          }).catch(function(e){
            alert("We had an issue. Try again later to see the prizes list.")
          	window.reward_students_window.dialog( "close" );
          })

	}



	render(){
		var that = this;
		
		if(that.state.np === undefined || that.state.np === null){
			return (<div> No project
				</div>)
		}
		
		return (
		<div>
			<div style = {{position:"absolute", top:0,left:"50%", width:"50%",height:"100%"}}>
				<p style = {{textDecorationLine:"underline", color:"black",textAlign:"center",fontWeight:"bold"}}> <span style = {{color:"red"}}>Second)</span> {" Select students to reward for " + that.state.np.np_name}</p>
				<p style = {{color:"grey", textAlign:"center"}}>{"Selected students: " + that.state.selected_students.join(", ") } </p>
				
				<div style = {{display:"inline-block", width:"80%", marginLeft:"10%", height:"40%",overflowY:"scroll", alignSelf:"10%",backgroundColor:"black"}}>
				 {that.state.students.filter(function(a){
				 	if(!a.username || a.username.trim().length < 2){
				 		return false
				 	}
				 	return true
				 }).sort(function(a, b){
				    if(a.username.toUpperCase() < b.username.toUpperCase()) { return -1; }
				    if(a.username.toUpperCase() > b.username.toUpperCase()) { return 1; }
				    return 0;
				}).map(function(student){
							 	return (
							 	<div style = {{display:"block",alignItems:"center",justifyContent:"center"}}>
				 		<input type="checkbox" class = "selectStudentCheckbox"  checked={
					 		that.state.selected_students.indexOf(student.username) !== -1
					 	}
					 	onChange={function(a){
					 		that.state.selected_students.push(student.username);
					 		console.log(that.state.selected_students)
					 		that.setState({selected_students:that.state.selected_students})
					 	}}
					 	></input>
					 	<span style = {{fontSize:"2vw", color:"white"}}>{student.username}</span>
				 	</div>
				 	)
				 })}
				</div>
				<br/>
				<br/>
				<button style = {{backgroundColor:"red",color:"white",width:"80%", marginLeft:"10%"}} onClick = {that.submit.bind(that)}>Submit</button>

			</div>
			<div style = {{position:"absolute", top:0,left:"0%", width:"50%",height:"100%"}}>
				<p style = {{color:"black",textAlign:"center",fontWeight:"bold",textDecorationLine:"underline"}}><span style = {{color:"red"}}>First)</span> {" Email " + that.state.np.np_name + " their app"}</p>
				<p style = {{color:"grey", textAlign:"center"}}>Copy and paste this into an email your class can send </p>
				
				<div style = {{display:"inline-block", width:"80%", marginLeft:"10%", height:"40%",overflowY:"scroll", alignSelf:"10%",backgroundColor:"black"}}>
				 <p id = "completedAppMessage" style = {{whiteSpace:"pre-wrap"}}>
				 {
				 	`
Hello!

We wanted to present you with your finished app! 

Thanks for letting us work on this. Our class loved working on it.

This is the link to the finished app:

` + 

"https://appbuilderdev.herokuapp.com?f=true&n=" + that.state.np .phone_id

+
`
The app is live on the web. You can send it to your employees, business partners, or customers to have them use it. They can also save it on their phones as an app.

Thank you! By the way, if you found this app useful, or just liked it, could you send us a brief video? In this class, we're supposed to show 30 second thanks-videos from our nonprofits to our parents and teachers to show what we accomplished, so it would be really helpful if you are able to do that, if you have the time. You can send the brief (<30 second) video to admin@vineyardappcamp.com with the subject line "NonProfit Thanks".


Best,
`
				 }
				 </p>
				</div>
				<br/>
				<br/>
				<button style = {{backgroundColor:"red",color:"white",width:"80%", marginLeft:"10%"}} onClick = {function(){
					window.copyToClipboard(document.getElementById("completedAppMessage"))
					alert("Copied!")
				}}>Copy</button>

			</div>
		</div>
		)
		

	}

}


export default RewardStudents;